<template>
  <div class="page-header clear-filter" filter-color="green">
    <div class="page-header-image" :style="backgroundLogin"></div>
    <div class="content">
      <form @submit.prevent="handleSubmit">
        <div class="container">
          <div class="col-md-5 ml-auto mr-auto">
            <card type="login" plain>
              <div slot="header" class="content-top brand">
                <h3 class="h1-seo description">FAIXA DE DOMÍNIO</h3>
              </div>

              <p class="h1-seo description">Informe abaixo seu email ou login de acesso para solicitar a recuperação da senha.</p>
              <fg-input
                class="no-border input-lg"
                addon-left-icon="now-ui-icons users_circle-08"
                placeholder="Informe login ou e-mail"
                v-model="userName"
              ></fg-input>

              <template slot="raw-content">
                <div class="card-footer text-center">
                  <b-btn class="btn-primary btn-round" type="submit">Recuperar</b-btn>
                  <b-btn class="btn-secondary btn-round m-2" @click="handleCancelar">Cancelar</b-btn>
                </div>
              </template>
            </card>
          </div>
        </div>
      </form>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script lang='js'>
import { Card, Button, FormGroupInput } from '@/components';
import MainFooter from '@/layout/MainFooter';
import Vue from 'vue';

export default {
  name: 'reset-page',
  bodyClass: 'register-page',
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      userName: '',
      submitted: false,
      backgroundLogin: "background-image: url('img/trevo.jpg')",
    }
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      
      const options = {}; // 'headers': { 'Authorization': `Bearer ${token}` } };
      const body = { login: this.userName };

      return Vue.axios.post(`/api/reset/demandas/FXD`, body, options)
        .then(() => {
          this.$router.push('/resetInfo');
        })
        .catch((error) => {
          this.$notify({
            group: 'msgs',
            title: 'Catch',
            text: error.response.data.message,
            type: 'error'
          });
        });
    }, 
    handleCancelar() {
      this.$router.push('/login')
    }
  },
   mounted() {
    if (window.config && window.config.SIGLA_ASSETS) {
        const siglaAssets = window.config.SIGLA_ASSETS;
        this.backgroundLogin = `background-image: url('img/${siglaAssets}/trevo.jpg')`;
    }
  },


};
</script>
<style></style>
