import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { account } from './account.module';
import { alert } from './alert.module';
import { empresa } from './empresa.module';
import { logomarca } from './logomarca.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    account,
    alert,
    empresa,
    logomarca
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
});
