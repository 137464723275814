<template>
  <gmaps-map :options="mapOptions" :style="mapStyle" :zoom="10" @click="gotoDemanda">
    <gmaps-marker
      :key="index"
      :options="getMarkerPosition(marker)"
      v-for="(marker, index) in markers"
      @click="toggleInfoWindow(index)"
    />

    <gmaps-polyline :path="getCoordenadas(rdv.id)" strokeColor="blue" v-for="(rdv, idx) in rodovias" :key="'$rdv'-idx" />
    <gmaps-info-window :options="infoOptions" ref="infoW" v-if="infoWinOpen == true" @closed="clearInfoWindow()" />
  </gmaps-map>
</template>

<script lang="js">
import { gmapsMap, gmapsMarker, gmapsInfoWindow, gmapsPolyline } from 'x5-gmaps'

export default {
  name: 'mapas',
  components: { gmapsMap, gmapsMarker, gmapsInfoWindow, gmapsPolyline },
  bodyClass: 'novaDemanda-page',
  props: {
    demandas: Array,
    demanda: Object,
    kmlData: Array,
    coordenadas: Array,
    rodovias: Array
  },
  data() {
    return {
      geoLocationBase: {
        lat:-23.550278,
        lng: -46.633889
      },
      infoOptions: {
        content: '',
        //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWinOpen: false,
      infoWindowPos: null,
      kml: undefined,
      mapOptions: {
        center: this.geoLocationBase,
        zoom: 10,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      mapHeight: 600,
      mapWidth: 400,
      mapStyle: 'width: 99%; height: 500px;',
      markers: []
    }
  },
  mounted() {
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    this.mapWidth = w - 40;
    this.mapHeight = h - 20;

    this.mapStyle = 'width: 99%; height:' + this.mapHeight + 'px';

    if (this.demandas !== undefined) {
      for(const demanda of this.demandas ) {
        this.makeMarker(demanda);
      }
    } else if (this.demanda !== undefined) {
      if (this.kmlData !== undefined) {
        this.loadKmlData(this.demanda);
      }
      else {
        this.makeMarker(this.demanda);
      }
    }

    if (this.markers!= null && this.markers.length > 0) {
      this.geoLocationBase.lat = this.markers[0].position.lat;
      this.geoLocationBase.lng = this.markers[0].position.lng;

      this.mapOptions.center = this.geoLocationBase;
    }

     // This event listener calls addMarker() when the map is clicked.
     /*
    this.$refs.mapRef.$mapPromise.then(() => {
      const mapObj = this.$refs.mapRef.$mapObject;
      gmapApi.maps.event.addListener(mapObj, 'click', (event) => {
        this.addMarker(event.latLng, mapObj);
      });
    });
    */
  },
  methods: {
    gotoDemanda() {
      // console.log('latLng=', latLng);
      // console.log('mapObj=', mapObj);
     this.$router.push('/novaDemanda');
    },
    getCoordenadas(rodovia) {
      if (rodovia == undefined || this.coordenadas == undefined)
        return [];

      const items = [];
      for (var coord of this.coordenadas) {
        if (coord.idRodovia == rodovia.id) {
          items.push({lat: coord.latitude , lng: coord.longitude});
        }
      }

      return items;
    },
    getDemandaUrl(demanda) {
      const ctxPath = window.location.pathname.substring(0, window.location.pathname.indexOf("/", 2));
      return ctxPath + '/demanda/' + demanda.id.idDemanda;
    },
    getMarkerPosition(marker) {
      const obj = {
        position: {
          lat: parseFloat(marker.position.lat),
          lng: parseFloat(marker.position.lng)
        },
        title: marker.titulo
      };

      return obj;
    },
    loadKmlData(demanda) {
      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const idDemanda = demanda.id.idDemanda;

      if (demanda.latitudeInicial!== undefined) {
        this.geoLocationBase.lat = this.demanda.latitudeInicial;
      }
      else {
        if (demanda.cidade !== undefined && demanda.cidade.latitude !== undefined)
          this.geoLocationBase.lat = this.demanda.cidade.latitude;
      }

      if (demanda.longitudeInicial!== undefined) {
        this.geoLocationBase.lng = this.demanda.longitudeInicial;
      }
      else {
        if (demanda.cidade !== undefined && demanda.cidade.longitude !== undefined)
          this.geoLocationBase.lng = this.demanda.cidade.longitude;
      }

      console.log('geolocationBase==>', this.geoLocationBase);

      // var apiUrl = process.env.NODE_ENV === 'production'?window.config.VUE_APP_API_LOCATION:process.env.VUE_APP_API_LOCATION;
      var apiUrl = window.config.VUE_APP_API_LOCATION;

      this.$GMaps().then((maps) => {
        var options = {
            preserveViewport: true,
            clickable: false,
            visibility: "visible",
            url: `${apiUrl}/mapsKmlData?empresa=${sigla}&idDemanda=${idDemanda}`
        };

        console.log('options.URL=', options.url);

        this.kml = new maps.KmlLayer(options);
        this.kml.setMap(maps.mapRef);
      });
    },
    makeMarker(demanda) {
      const trecho = demanda.trecho;
      const statusInfo = demanda.statusInfo;
      let rodoviaDesc = "Rodovia - Não informada";
      if (trecho !== undefined && trecho.rodovia !== undefined) {
        rodoviaDesc = trecho.rodovia.descricao;
      }

      let statusInfoDesc = "Status - Não informado.";
      if (statusInfo !== undefined) {
        statusInfoDesc = statusInfo.descricao;
      }

      if (demanda.latitudeInicial !== undefined) {
        this.markers.push({
          position:{ lat: demanda.latitudeInicial, lng: demanda.longitudeInicial },
          titulo: demanda.titulo,
          infoText: '<b><a href="' + this.getDemandaUrl(demanda) + '">' + demanda.titulo + '</a></b><br/>' +
            'Rodovia: ' + rodoviaDesc +  ' - ' + ' Km ' + demanda.kmInicial + ' ao Km ' + demanda.kmFinal +
            '<br/>' + statusInfoDesc
        });
      } else if (demanda.latitudeFinal !== undefined) {
        this.markers.push({
          position: { lat: demanda.latitudeFinal, lng: demanda.longitudeFinal },
          titulo: demanda.titulo,
          infoText: '<b><a href="' + this.getDemandaUrl(demanda) + '">' + demanda.titulo + '</a></b><br/>' +
            'Rodovia: ' + rodoviaDesc +  ' - ' + ' Km ' + demanda.kmInicial + ' ao Km ' + demanda.kmFinal +
            '<br/>' + statusInfoDesc
          });
      }
    },
    clearInfoWindow() {
      this.infoOptions.title = undefined;
      this.infoOptions.content = null;
      this.infoOptions.position = undefined;
      this.infoWinOpen = false;
    },
    toggleInfoWindow(index) {
      this.infoOptions.title = this.markers[index].titulo;
      this.infoOptions.content= this.markers[index].infoText;
      this.infoOptions.position = this.markers[index].position;
      this.infoWinOpen = true;
    },
  }
}
</script>
