<template>
  <div>
    <div class="page-header page-header-small">
      <parallax class="page-header-image" :style="backgroundTopo"></parallax>
      <div class="content-center">
        <div class="content-center brand">
          <h2 class="h1-seo">Perguntas Frequentes</h2>
        </div>
      </div>
    </div>

    <div class="section section-contact-us text-center">
      <div class="container">
        <form @submit.prevent="onSubmit" class="col-md-8 ml-auto mr-auto text-center">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="secondary">{{ dados[0].pergunta }}</b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>{{ dados[0].resposta }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="secondary">{{ dados[1].pergunta }}</b-button>
              </b-card-header>
              <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>{{ dados[1].resposta }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-3 variant="secondary">{{ dados[2].pergunta }}</b-button>
              </b-card-header>
              <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>{{ dados[2].resposta }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-4 variant="secondary">{{ dados[3].pergunta }}</b-button>
              </b-card-header>
              <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>{{ dados[3].resposta }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'faqs',
  bodyClass: 'novaDemanda-page',

  data() {
    return {
      backgroundTopo: "background-image: url('/img/solicitacao.jpg')",
      dados: [
        {
          pergunta: '1. Como devo criar solicitações ?',
          resposta: "Você deve criar solicitações a partir da opção 'Nova solicitação'. ",
        },
        {
          pergunta: '2. Qual é o tamanho máximo dos arquivos a serem enviados ?',
          resposta: 'Para cada arquivo a ser enviado vocë pode enviar um total de 10mb por arquivo.',
        },
        {
          pergunta: '3. Qual é o prazo estimado para atendimento da solicitação ?',
          resposta: 'O prazo médio estimado irá depender do tipo de solicitação, oscilando entre 7 a 20 dias. ',
        },
        {
          pergunta: '4. Como funciona o processo da solicitação ?',
          resposta:
            '1. A solicitação é gerada; 2. A concessionária realiza a análise; 3. A concessionária aprova os dados e documentos; ',
        },
      ],
    };
  },
};
</script>
