import Vue from 'vue';

import { authHeader } from '../_helpers';

export const userService = {
  login,
  logout,
  register,
  registerConfirm,
  resetConfirm,
  getAll,
  getById,
  update,
  delete: _delete
};

function login(userName, password) {
  return Vue.axios
    .post(`/api/auth`, { userName, password })
    .then(response => {
      if (response.statusText !== 'OK') {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          logout();
          return;
          // location.reload(true);
        }
      }

      console.log('response ==> ', response);

      const user = response.data;
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', JSON.stringify(user.token));
        localStorage.setItem('tipo', JSON.stringify(user.tipo));

        if (user.empresas) {
          localStorage.setItem('empresas', JSON.stringify(user.empresas));
          localStorage.setItem('empresaAtual', user.empresas[0].sigla);
        }

        // carrega as logomarcas
        // fillLogomarcas();
      }

      return user;
    })
    .catch(error => handleResponse(error.response));
}

// function fillLogomarcas() {
//   var hasLogos = localStorage.getItem('logomarcas');
//   if (hasLogos == undefined || hasLogos == null) {
//     empresaService.getLogomarcas().then(response => {
//       const logomarcas = response.data;
//       localStorage.setItem('logomarcas', JSON.stringify(logomarcas));
//     });
//   }
// }

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
  localStorage.removeItem('empresas');
  localStorage.removeItem('tipo');
  localStorage.removeItem('empresaAtual');
  sessionStorage.clear();
}

function register(sigla, user) {
  return Vue.axios.post(`/api/register/${sigla}`, user);
}

function registerConfirm(payloadReset, userId) {
  payloadReset.login = userId;
  return Vue.axios.post(`/api/register/confirm`, payloadReset);
}

function resetConfirm(userId) {
  return Vue.axios.post(`/api/reset/confirm/${userId}`);
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`/users`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  console.log('response==>', response);
  return new Promise((resolve, reject) => {
    if (response === undefined || response == null) {
      reject('Impossível comunicar com servidor.');
    }

    const data = response.data;
    if (response.statusText !== 'OK') {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
      }

      let error = '';
      if (data) {
        if (data.message) {
          if (data.message == '' && data.error) error = data.error;
          else error = data.message;
        } else {
          if (data.error) error = data.error;
          else error = data;
        }
      } else {
        error = response.statusText;
      }

      reject(error);
    }

    resolve(data);
  });
}
