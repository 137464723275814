<template>
  <gmaps-Map :options="mapOptions" :style="mapStyle" :zoom="10" @click="addMarker(latLng, mapObj)">
    <gmaps-heatmap :items="getHeatmapItems()" :opacity="0.8" />
  </gmaps-Map>
</template>

<script lang="js">
import { gmapsMap, gmapsHeatmap } from 'x5-gmaps'

export default {
  name: 'mapa-calor',
  components: { gmapsMap, gmapsHeatmap },
  bodyClass: 'novaDemanda-page',
  props: {
    demandas: Array,
    demanda: Object,
    kmlData: Array
  },
  data() {
    return {
      geoLocationBase: {
        lat:-23.550278,
        lng: -46.633889
      },
      mapOptions: {
        center: this.geoLocationBase,
        zoom: 10,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      mapHeight: 600,
      mapWidth: 400,
      mapStyle: 'width: 99%; height: 500px;',
      markers: []
    }
  },
  mounted() {
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    this.mapWidth = w - 40;
    this.mapHeight = h - 20;

    this.mapStyle = 'width: 99%; height:' + this.mapHeight + 'px';

    if (this.demandas !== undefined) {
      for(const demanda of this.demandas ) {
        this.makeMarker(demanda);
      }
    } else if (this.demanda !== undefined) {
      if (this.kmlData !== undefined) {
        this.loadKmlData(this.demanda);
      }
      else {
        this.makeMarker(this.demanda);
      }
    }

    if (this.markers!= null && this.markers.length > 0) {
      this.geoLocationBase.lat = this.markers[0].position.lat;
      this.geoLocationBase.lng = this.markers[0].position.lng;

      this.mapOptions.center = this.geoLocationBase;
    }
  },
  methods: {
    addMarker(latLng, mapObj) {
      console.log('latLng=', latLng);
      console.log('mapObj=', mapObj);
     this.$router.push('/novaDemanda');
    },
    getDemandaUrl(demanda) {
      const ctxPath = window.location.pathname.substring(0, window.location.pathname.indexOf("/", 2));
      return ctxPath + '/demanda/' + demanda.id.idDemanda;
    },
    getHeatmapItems() {
      var items = [];
      for(const marker of this.markers) {
        items.push({lat: marker.position.lat, lng: marker.position.lng});
      }

      return items;
    },
    getMarkerPosition(marker) {
      const obj = {
        position: {
          lat: parseFloat(marker.position.lat),
          lng: parseFloat(marker.position.lng)
        }
      };

      return obj;
    },
    loadKmlData(demanda) {
      var self = this;

      const sigla = this.$store.state.empresa.empresaAtual.sigla;
      const idDemanda = demanda.id.idDemanda;

      if (demanda.latitudeInicial!== undefined) {
        this.geoLocationBase.lat = this.demanda.latitudeInicial;
      }
      else {
        if (demanda.cidade !== undefined && demanda.cidade.latitude !== undefined)
          this.geoLocationBase.lat = this.demanda.cidade.latitude;
      }

      if (demanda.longitudeInicial!== undefined) {
        this.geoLocationBase.lng = this.demanda.longitudeInicial;
      }
      else {
        if (demanda.cidade !== undefined && demanda.cidade.longitude !== undefined)
          this.geoLocationBase.lng = this.demanda.cidade.longitude;
      }

      console.log('geolocationBase==>', this.geoLocationBase);

      var apiUrl = process.env.NODE_ENV === 'production'?window.config.VUE_APP_API_LOCATION:process.env.VUE_APP_API_LOCATION;

      this.$GMaps().then((maps) => {
        var options = {
            preserveViewport: true,
            clickable: false,
            visibility: "visible",
            url: `${apiUrl}/mapsKmlData?empresa=${sigla}&idDemanda=${idDemanda}`
        };

        self.kml = new maps.KmlLayer(options);
        self.kml.setMap(maps.mapRef);
      });
    },
    makeMarker(demanda) {
      const trecho = demanda.trecho;
      const statusInfo = demanda.statusInfo;
      let rodoviaDesc = "Rodovia - Não informada";
      if (trecho !== undefined && trecho.rodovia !== undefined) {
        rodoviaDesc = trecho.rodovia.descricao;
      }

      let statusInfoDesc = "Status - Não informado.";
      if (statusInfo !== undefined) {
        statusInfoDesc = statusInfo.descricao;
      }

      if (demanda.latitudeInicial !== undefined) {
        this.markers.push({
          position:{ lat: demanda.latitudeInicial, lng: demanda.longitudeInicial },
          infoText: '<b><a href="' + this.getDemandaUrl(demanda) + '">' + demanda.titulo + '</a></b><br/>' +
            'Rodovia: ' + rodoviaDesc +  ' - ' + ' Km ' + demanda.kmInicial + ' ao Km ' + demanda.kmFinal + 
            '<br/>' + statusInfoDesc
        });
      } else if (demanda.latitudeFinal !== undefined) {
        this.markers.push({
          position: { lat: demanda.latitudeFinal, lng: demanda.longitudeFinal },
          infoText: '<b><a href="' + this.getDemandaUrl(demanda) + '">' + demanda.titulo + '</a></b><br/>' +
            'Rodovia: ' + rodoviaDesc +  ' - ' + ' Km ' + demanda.kmInicial + ' ao Km ' + demanda.kmFinal + 
            '<br/>' + statusInfoDesc
          });
      }
    },
  }
}
</script>
